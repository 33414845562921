<template>
  <div class="service">

    <div class="container">
      <div class="row px-3 d-flex justify-content-between">
        <div class="col-sm-3 p-0 border border-bottom-0">
          <b-button to="/admin" variant="white" block class="text-muted">
            <i class="fad fa-ticket-alt"></i><span class="pl-2">Usuario: Administrador</span>
          </b-button>
        </div>
        <div class="col-sm-3 p-0 border border-bottom-0">
          <b-button to="/report" variant="primary" block class="text-white rounded-0">
            <i class="fad fa-file"></i><span class="pl-2">Reportes</span>
          </b-button>
        </div>
      </div>
    </div>

    <div class="container animate__animated animate__fadeIn">

      <hr class="mt-0">
      
      <div class="row px-4 d-flex justify-content-between">
        <div class="col-sm-6 px-0">
          <div class="text-primary"><h5>Reportes</h5></div>
        </div>
      </div>

      <div class="row py-2">

        <div class="col-sm-2">
          <b-form-select v-model="filters.status" :options="options.status"></b-form-select>
        </div>

        <div class="col-sm-3">
          <b-form-select v-model="filters.type" :options="options.type"></b-form-select>
        </div>

        <div class="col-sm-2">
          <b-form-select v-model="filters.place" :options="options.place"></b-form-select>
        </div>

        <div class="col-sm-2">
          <b-input-group class="mb-3">
            <b-form-input
              v-model="filters.date_start"
              type="text"
              placeholder="Fecha inicial"
              autocomplete="off"
              disabled
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filters.date_start"
                button-only
                right
                locale="en-MX"
                aria-controls="example-input"
                @context="onContextStart"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </div>

        <div class="col-sm-2">
          <b-input-group class="mb-3">
            <b-form-input
              v-model="filters.date_end"
              type="text"
              placeholder="Fecha final"
              autocomplete="off"
              disabled
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filters.date_end"
                button-only
                right
                locale="en-MX"
                aria-controls="example-input"
                @context="onContextEnd"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </div>

        <div class="col-sm-1">
          <b-button v-on:click="get_tickets()" block>
            <i class="fas fa-filter"></i>
          </b-button>
        </div>

      </div>

      <div class="row"><div class="col-12"><hr class="mt-0"></div></div>

      <div class="row px-4 d-flex justify-content-between">
        <div class="col-sm-6 px-0">
          <div class="text-primary"><h5>Resumen</h5></div>
          <p class="mb-0">Tickets Totales: {{ tickets.length }}</p>
          <p>Ganancias Totales: $ {{ total_amount }}</p>
        </div>
      </div>

      <div class="row px-4 d-flex justify-content-between">
        <div class="col-sm-6 px-0 pb-2">
          <div class="text-primary"><h5>Listado</h5></div>
        </div>
      </div>

      <b-list-group class="mb-5">
        <b-list-group-item href="#" class="mb-3 border bg-light" v-for="(item, key) in tickets" :key="key">
          
          <div class="row">
            <div class="col-sm-6">
              <p class="mb-0 text-primary"><strong>Ticket</strong></p>
              <p class="mb-0">
                <span class="text-muted">Id: </span>
                <span>{{ item.id }}</span> 
                <span v-if="item.group_id == 2"> - Vallarta</span>
                <span v-if="item.group_id == 4"> - Plaza de la Tecnología</span>
              </p>
              <p class="mb-0">
                <span :class="item.status_class" class="pr-2">
                  <i class="fas fa-circle"></i>
                </span>
                <span :class="item.status_class">{{ item.status }}</span>
              </p>
              <p class="mb-0"><span class="text-muted">Recibido:</span> {{ item.date_added }}</p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0 text-primary"><strong>Cliente</strong></p>
              <p class="mb-0"><span class="text-muted">Nombre:</span> {{ item.customer_name }}</p>
              <p class="mb-0">
                <span class="text-muted pr-1">Teléfono:</span> 
                <a v-on:click="send_whatsapp(item.customer_phone)" href="#">{{ item.customer_phone }}</a>
              </p>
              <p class="mb-0">
                <span class="text-muted pr-1">Email:</span>
                <a :href="'mailto:' + item.customer_email">{{ item.customer_email }}</a>
              </p>
            </div>
          </div>

          <div class="actions pt-2 animate__animated animate__fadeIn">
            <div class="product_list">
              <p v-if="item.product_list.length > 0" class="text-primary"><strong>Productos</strong></p>
              <div v-for="(product, pkey) in item.product_list" :key="pkey" class="px-2 bg-white rounded mb-2">
                <div class="row py-2">
                  <div class="col-sm-6">
                    <p class="mb-0"><strong>{{ product.type }} - {{ product.name }}</strong></p>
                    <p class="mb-0">{{ product.description }}</p>
                    <p class="mb-0" v-if="product.subtotal > 0">Precio: $ {{ product.subtotal }}</p>
                  </div>
                  <div class="col-sm-6 my-auto">
                    <b-button 
                      :variant="(product.status.matrix) ? 'success' : 'white'" class="py-1 border mr-2">
                      <span class="small"><i class="fal fa-garage"></i><span class="pl-2">En matríz</span></span>
                    </b-button>
                    <b-button 
                      :variant="(product.status.provider) ? 'success' : 'white'" class="py-1 border mr-2">
                      <span class="small"><i class="fal fa-building"></i><span class="pl-2">En proveedor</span></span>
                    </b-button>
                    <b-button 
                      :variant="(product.status.store) ? 'success' : 'white'" class="py-1 border mr-2">
                      <span class="small"><i class="fal fa-store"></i><span class="pl-2">En sucursal</span></span>
                    </b-button>
                    <b-button 
                      :variant="(product.status.shipped) ? 'success' : 'white'" class="py-1 border mr-2">
                      <span class="small"><i class="fal fa-hand-holding-box"></i><span class="pl-2">Entregado</span></span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </b-list-group-item>
      </b-list-group>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import settings from '@/settings'

export default {
  name: 'Report',
  data() {
    return {
      token: '',
      tickets: '',

      filters: '',
      options: '',

      total_amount: ''
    }
  },
  methods: {
    async get_tickets() {
      this.tickets = []
      axios.post(settings.api().endpoint + settings.api().path + '/get_tickets_by', this.filters, { headers: { xwt: this.token } }).then( (r) => {
        this.tickets = r.data.data.list
        this.total_amount = r.data.data.total_amount
      })
    },
    onContextStart(ctx) { this.filters.date_start = ctx.selectedYMD },
    onContextEnd(ctx) { this.filters.date_end = ctx.selectedYMD },
  },
  mounted() {
    this.token = this.$store.getters.get_token
    this.group_id = this.$store.getters.get_customer['customer_group_id']
    this.tickets = []

    this.filters = {
      status: null,
      type: null,
      place: null,
      date_start: '',
      date_end: ''
    }

    this.suma_precios = 0

    this.options = {
      status: [
        { value: null, text: 'Filtrar por status (Todos por defecto)' },
        { value: 'Todos', text: 'Iniciados y Terminados' },
        { value: 'Iniciado', text: 'Iniciado' },
        { value: 'Terminado', text: 'Terminado' },
      ],
      type: [
        { value: null, text: 'Filtrar por tipo  (Todos por defecto)' },
        { value: 'Todos', text: 'Servicios y Garantías' },
        { value: 'Servicio', text: 'Servicio' },
        { value: 'Garantía', text: 'Garantía' },
      ],
      place: [
        { value: null, text: 'Filtrar por lugar  (Todos por defecto)' },
        { value: 'Todos', text: 'Todos' },
        { value: 4, text: 'Plaza de la Tecnología' },
        { value: 2, text: 'Vallarta' },
      ]
    }

    if(this.group_id != 5 && this.group_id != 2 && this.group_id != 4) this.$router.push('/')
  },
}
</script>
